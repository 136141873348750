<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div id="app">
    <MyHeader />
    <MultiCarousel />
    <AboutUs />
    <!-- <MyCase /> -->
    <ProductList />
    <MyFooter />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import MainWeb from './components/MainWeb.vue'
  import MyHeader from './components/MyHeader.vue';
  import MultiCarousel from './components/MultiCarousel.vue';
  import AboutUs from './components/AboutUs.vue';
  // import MyCase from './components/MyCase.vue';
  import ProductList from './components/ProductList.vue';
  import MyFooter from './components/MyFooter.vue';

export default {
  name: 'App',
  components: {
    MyHeader,
    MultiCarousel,
    AboutUs,
    // MyCase,
    ProductList,
    MyFooter,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
</style>
