<template>
  <header class="header">
    <nav class="nav">
      <ul>
        <li v-for="item in navItems" :key="item.text">
          <a href="#" @click.prevent="scrollToSection(item.id)">{{ item.text }}</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'MyHeader',
  data() {
    return {
      navItems: [
        { text: '公司简介', id: 'about' },
        { text: '合作案例', id: 'cases' },
        { text: '产品展示', id: 'products'},
        { text: '关于我们', id: 'about'}, 
        { text: '联系我们', id: 'contact-us'},
        // 其他菜单项...
      ],
    };
  },
  methods: {
    scrollToSection(id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动到目标位置
      }
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.header::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60%; /* 背景色左右60% */
  height: 50%; /* 背景色50%高度 */
  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 底部阴影 */
  z-index: -1;
  border-radius: 10px; /* 可选：增加圆角 */
}

.nav {
  display: flex;
  justify-content: center; /* 水平居中 .nav */
  width: 100%; /* 使 .nav 占据整个宽度 */
}

.nav ul {
  list-style: none;
  display: flex;
  justify-content: center; /* 水平居中 ul */
  margin: 0;
  padding: 0;
}

.nav li {
  margin: 0 10px; /* 调整每个菜单项的间距 */
}

.nav a {
  color: #fff;
  text-decoration: none;
  font-size: 16px; /* 字体稍微调小 */
  padding: 8px 12px; /* 减少选项间距 */
  transition: transform 0.3s, background 0.3s; /* 添加过渡效果 */
  display: inline-block;
}

.nav a:hover {
  transform: scale(1.1); /* 字体放大 */
  border-radius: 5px;
  background: linear-gradient(to right, #2c577fb1, #c0c0c0e6); /* 背景滑块效果 */
}
</style>




<!-- <template>
  <header class="header">
    <nav class="nav">
      <ul>
        <li v-for="item in navItems" :key="item.text">
          <a href="#" @mouseover="hoverEffect" @mouseleave="removeEffect">{{ item.text }}</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'MyHeader',
  data() {
    return {
      navItems: [
        { text: '公司简介' },
        { text: '合作案例' },
        { text: '产品展示' },
        { text: '关于我们' },
        { text: '联系我们' },
        // 其他菜单项...
      ],
    };
  },
  methods: {
    hoverEffect(event) {
      event.target.style.transform = 'scale(1.1)';
      event.target.style.background = 'linear-gradient(to right, #ff7e5f, #feb47b)';
    },
    removeEffect(event) {
      event.target.style.transform = 'scale(1)';
      event.target.style.background = 'none';
    }
  }
};
</script>

<style scoped>
.header {
  box-shadow: 0 4px 8px rgba(199, 199, 199, 0.1); /* 底部阴影 */
  padding: 20px;  
  background-color: #333;
  
}

.nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.nav li {
  position: relative;
}

.nav a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 15px;
  transition: transform 0.3s, background 0.3s; /* 添加过渡效果 */
  display: inline-block;
}

.nav a:hover {
  transform: scale(1.1); /* 字体放大 */
  background: linear-gradient(to right, #ff7e5f, #feb47b); /* 背景滑块效果 */
}
</style> -->



<!-- 
<template>
    <header class="header">
      <div class="logo">
        <img src="@/assets/case1.png" alt="Logo" />
      </div>
      <nav class="nav-menu">
        <ul>
          <li><a href="#about">公司简介</a></li>
          <li><a href="#cases">合作案例</a></li>
          <li><a href="#products">产品展示</a></li>
          <li><a href="#about-us">关于我们</a></li>
          <li><a href="#contact">联系我们</a></li>
        </ul>
      </nav>
      <div class="language-switcher">
        <button>EN</button>
        <button>中文</button>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'MyHeader'
  };
  </script>
  
  <style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo img {
    height: 40px;
  }
  
  .nav-menu ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .nav-menu a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  .language-switcher button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #f5f5f5;
    cursor: pointer;
  }
  </style>
   -->