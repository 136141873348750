<template>
  <footer class="footer">
    <div class="quick-links">
      <a href="#">隐私政策</a> | 
      <a href="#">服务条款</a> | 
      <a @mouseover="showQRCode" @mouseleave="hideQRCode" @mousemove="moveQRCode">联系我们</a>
      <img v-if="qrVisible" :style="qrCodeStyle" src="@/assets/myqrcode.jpg" alt="QR Code" class="qr-code" />
    </div>
    <div class="social-media">
      <!-- 社交媒体图标部分 -->
    </div>
    <div class="copyright">
      <p>&copy; {{ creationYear }} - {{ currentYear }} 深圳市龙华区星舟信息技术工作室. All rights reserved.</p>
      <a href="https://beian.miit.gov.cn/" rel="noopener noreferrer" target="_blank" p-id="81" data-spm-anchor-id="a313x.search_index.i7.3">粤ICP备2023083489号-1</a>
    </div>
  </footer>
</template>


<script>
export default {
  data() {
    return {
      creationYear: 2024,
      currentYear: new Date().getFullYear(),
      qrVisible: false, // 控制二维码显示隐藏
      qrCodeStyle: { top: '0px', left: '0px' } // 控制二维码位置
    };
  },
  methods: {
    showQRCode() {
      this.qrVisible = true; // 显示二维码
    },
    hideQRCode() {
      this.qrVisible = false; // 隐藏二维码
    },
    moveQRCode(event) {
      // 获取鼠标位置并更新二维码位置到右上方
      this.qrCodeStyle = {
        top: `${event.clientY - 120}px`, // 设置二维码在鼠标的上方
        left: `${event.clientX + 20}px` // 设置二维码在鼠标的右边
      };
    }
  }
};
</script>


<style scoped>
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.quick-links {
  margin-bottom: 10px;
  font-size: 12px;
}

.quick-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  position: relative;
}

.qr-code {
  position: fixed;
  width: 100px;
  height: 100px;
  display: block;
  z-index: 1000; /* 确保二维码在页面上层显示 */
}

.social-media a {
  margin: 0 10px;
  display: inline-block;
}

.social-media img {
  width: 24px;
  height: 24px;
}

.copyright {
  margin-top: 15px;
  font-size: 14px;
}

.copyright a {
  margin-top: 15px;
  color: #fff;
}
</style>


