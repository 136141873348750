<template>
  <div class="products-section">
    <h2>产品展示</h2>
    <div 
      class="product-item" 
      v-for="(product, index) in products" 
      :key="index" 
      :class="{ 'reverse-layout': (index + 1) % 2 === 0 }"
    >
      <img :src="require(`@/assets/${product.image}`)" :alt="product.name" class="product-image" />
      <div class="product-info">
        <h3 class="product-name">{{ product.name }}</h3>
        <p class="product-description">{{ product.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductList",
  data() {
    return {
      products: [
        { 
          name: "烘焙蛋糕品牌门店小程序", 
          image: "case-1.png", 
          description: "我们专注于开发独具特色的烘焙蛋糕小程序，帮助商家轻松实现线上订单、客户管理、个性化定制服务等功能。通过我们的小程序，用户可以在线浏览精美的蛋糕作品，轻松选择心仪的款式并自定义口味、尺寸等细节，还能一键下单、跟踪订单状态。无论是生日庆典还是婚礼定制，我们的设计都确保用户享有流畅的购物体验，为商家带来高效的运营工具，提升品牌竞争力。" },
        { 
          name: "城市快餐品牌门店小程序", 
          image: "case-2.png", 
          description: "我们专注于为快餐行业提供高效、便捷的小程序解决方案，帮助品牌轻松实现数字化运营。通过我们的定制小程序，消费者可以快速浏览菜单、选择套餐、一键下单，同时支持多种支付方式。商家可以通过后台管理实时更新菜单、处理订单、查看销售数据，提升门店运营效率。此外，小程序还集成了会员系统与营销工具，帮助商家吸引回头客，增强品牌忠诚度。" },
        { 
          name: "城市公益元素小程序", 
          image: "case-3.png", 
          description: "我们致力于为各类公益组织、城市管理部门和社区项目提供专业的小程序开发服务。通过我们定制的城市公益小程序，用户可以便捷地参与各类公益活动、浏览最新的公益项目、捐款或报名志愿者服务。小程序还支持实时更新公益资讯、活动日历，并提供线上互动平台，促进社区成员的沟通与协作。无论是环保行动、慈善募捐，还是社区建设，我们的设计都将公益理念与数字化技术完美结合，助力城市公益事业的推广与发展。" },
        { 
          name: "美业品牌门店小程序", 
          image: "case-4.png", 
          description: "我们专为美容、美发、美甲等美业品牌提供数字化解决方案，帮助您轻松实现线上预约、服务管理与客户互动。通过我们的美业小程序，顾客可以随时查看门店服务、技师介绍以及优惠信息，便捷预约并选择心仪的时间和技师。商家则可通过后台轻松管理客户数据、安排技师日程、推广店铺活动，实现高效运营。我们的设计不仅提升品牌形象，还为顾客带来卓越的用户体验，助您在美业竞争中脱颖而出。 \n\n 立即定制您的美业品牌专属小程序，让您的服务触手可及！" },
        { 
          name: "宠物品牌门店小程序", 
          image: "case-5.png", 
          description: "我们专为宠物门店打造数字化运营方案，帮助您轻松管理门店服务和客户关系。通过我们的定制小程序，宠物主人可以在线浏览产品和服务，快速预约美容、医疗、训练等宠物服务，并享受便捷的在线支付功能。商家可以通过后台实时管理库存、客户订单、宠物档案，以及推出会员优惠活动，提升客户满意度。我们的设计不仅为您的品牌提供更高效的运营工具，还能打造温馨、专业的用户体验，增强客户对品牌的信赖。立即定制您的宠物品牌专属小程序，让您的宠物服务更智能、更贴心！" },
      ],
    };
  },
};
</script>

<style scoped>
.products-section {
  width: 80%;
  margin: 0 auto;
}

.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
}

.product-item.reverse-layout {
  flex-direction: row-reverse; /* 当序号为偶数时，图片在右侧 */
}

.product-image {
  max-width: 500px; /* 放大图片尺寸 */
  max-height: 500px; /* 放大图片尺寸 */
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 70px;
}

.product-item.reverse-layout .product-image {
  margin-right: 0;
  margin-left: 70px; /* 反向布局时调整间距 */
}

.product-info {
  flex: 1;
  text-align: left;
}

.product-name {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.product-description {
  font-size: 1.1rem;
  color: #555;
}
</style>





<!-- <template>
  <div class="products-section">
    <h2>产品展示</h2>
    <div class="products-container">
      <div class="arrow left-arrow" @click="scrollLeft" v-if="canScrollLeft">&#9664;</div>
      <div class="products" ref="products">
        <div class="product-item" v-for="(product, index) in displayedProducts" :key="index">
          <img :src="require(`@/assets/${product.image}`)" :alt="product.name" class="product-image" />
          <p>{{ product.name }}</p>
        </div>
      </div>
      <div class="arrow right-arrow" @click="scrollRight" v-if="canScrollRight">&#9654;</div>
    </div>
    <button @click="viewMore" v-if="displayedProducts.length < products.length">查看更多</button>
  </div>
</template>

<script>
export default {
  name: "ProductList",
  data() {
    return {
      products: [
        { name: "产品1", image: "case1.png" },
        { name: "产品2", image: "case1.png" },
        { name: "产品3", image: "case1.png" },
        { name: "产品4", image: "case1.png" },
        { name: "产品5", image: "case1.png" },
      ],
      displayedProducts: [],
      scrollPosition: 0,
      containerWidth: 0,
      contentWidth: 0,
    };
  },
  mounted() {
    this.displayedProducts = this.products.slice(0, 5);
    this.$nextTick(() => {
      this.containerWidth = this.$refs.products.offsetWidth;
      this.contentWidth = this.$refs.products.scrollWidth;
    });
  },
  computed: {
    canScrollLeft() {
      return this.scrollPosition > 0;
    },
    canScrollRight() {
      return this.scrollPosition + this.containerWidth < this.contentWidth;
    },
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.products;
      container.scrollBy({ left: -container.clientWidth, behavior: "smooth" });
      this.scrollPosition = container.scrollLeft - container.clientWidth;
    },
    scrollRight() {
      const container = this.$refs.products;
      container.scrollBy({ left: container.clientWidth, behavior: "smooth" });
      this.scrollPosition = container.scrollLeft + container.clientWidth;
    },
    viewMore() {
      this.displayedProducts = this.products;
    },
  },
};
</script>

<style scoped>
.products-section {
  position: relative;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
}

.products-container {
  display: flex;
  align-items: center;
  position: relative;
}

.products {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.product-item {
  min-width: 300px;
  margin-right: 100px;
  border-radius: 20rpx;
  text-align: center;
}

.product-image {
  max-width: 300px;
  max-height: 400px;
  width: auto;
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  user-select: none;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
</style> -->


  