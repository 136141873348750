<template>
    <section id="about" class="about">
      <h2>公司简介</h2>
      <p>
        我们是一家专注于创新设计和高效执行的专业团队，致力于为客户提供定制化的解决方案。我们的使命是通过设计与科技的融合，推动客户业务的发展。
        我们工作室专注于定制化小程序开发，致力于为各类企业、个人及创业者提供创新、智能的数字化解决方案。我们的团队由经验丰富的开发者和设计师组成，能够根据客户的需求量身打造高效、便捷的小程序应用。无论是小程序电商平台、在线服务、教育类应用还是企业内部系统，我们都能提供专业的定制开发服务，确保每个项目都具有卓越的用户体验和功能表现。我们的目标是通过技术赋能，助力客户在数字化时代中脱颖而出。
      </p>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutUs',
  };
  </script>
  
  <style scoped>
  .about {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .about h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .about p {
    font-size: 18px;
    line-height: 1.6;
    color: #666;
    text-align: justify;;
  }
  </style>
  