<template>
    <div class="carousel">
      <div v-for="(item, index) in multiCarousel" :key="index" class="slide">
        <img :src="item.image" :alt="item.title" />
        <div class="caption">{{ item.title }}</div>
      </div>
    </div>
</template>



<script>
import image1 from '@/assets/page1.jpg';
import image2 from '@/assets/page2.jpg';
import image3 from '@/assets/page3.jpg';

  export default {
    name: 'MultiCarousel',
    data() {
      return {
        multiCarousel: [
          { image: image1, title: '创新的设计' },
          { image: image2, title: '专业的团队' },
          { image: image3, title: '优质的服务' },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .slide {
    position: relative;
    width: 100%;
    height: 680px;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 5px;
  }
  </style>
  